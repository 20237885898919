import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import React from 'react';

import css from './MigrateBanner.module.css';
import { TranslatedMessage } from '../TranslatedMessage/TranslatedMessage';

export const MigrateBanner = () => {
  return (
    <FlexContainer
      alignItems="center"
      justifyContent="center"
      className={css.container}
      onClick={() => openLinkInBrowser('https://www.autocut.com/download/')}
    >
      <Text variant="textXs.semibold" color="black" className={css.text}>
        <TranslatedMessage
          id="migrate_banner_text"
          defaultMessage="This version of AutoCut is not maintained anymore. Download the new official release here: "
        />
        <Button variant="tertiary" color="black" fullWidth={false}>
          <Text variant="textXs.semibold" color="black" className={css.text}>
            <TranslatedMessage
              id="migrate_banner_cta"
              defaultMessage="Download AutoCut"
            />
          </Text>
        </Button>
      </Text>
    </FlexContainer>
  );
};
