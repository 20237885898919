import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React from 'react';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { closeModal } from '@autocut/utils/modal/closeModal';
import { CURRENT_ENV, Env } from '@autocut/utils/currentEnv.utils';
import { os, child_process } from '@autocut/lib/cep/node';
import path from 'path';
import { colors } from '@autocut/designSystem/colors';

import warningImage from '@autocut/assets/images/migration/warning.png';
import { csi } from '@autocut/lib/utils/bolt';

const startElectronApp = async () => {
  const platform = os.platform();
  const appName = CURRENT_ENV === Env.Production ? 'AutoCut' : 'AutoCutStaging';
  const appPath =
    platform === 'win32'
      ? path.join(os.homedir(), 'AppData', 'Local', appName, `${appName}.exe`)
      : path.join('/Applications', `${appName}.app`);

  if (platform === 'win32') {
    child_process.spawn(appPath, { detached: true, stdio: 'ignore' }).unref();
  } else {
    child_process
      .exec(`open "${appPath}"`, (error, stdout, stderr) => {
        if (error) {
          console.error(`exec error: ${error}`);
          return;
        }
        console.log(`stdout: ${stdout}`);
        console.error(`stderr: ${stderr}`);
      })
      .unref();
  }
};

export const LaunchV2Modal = () => {
  return (
    <Modal
      withCloseButton={false}
      closeOnClickOutside={false}
      title={
        <Text
          variant="textXl.bold"
          color="white"
          style={{ whiteSpace: 'nowrap' }}
        >
          <TranslatedMessage
            id="launchV2Modal_title"
            defaultMessage="You’re Still Using the Old Version of AutoCut"
          />
        </Text>
      }
      footer={
        <FlexContainer flexDirection="column" gap={Spacing.s2}>
          <Button
            color={colors.primary600}
            onClick={async () => {
              void startElectronApp();
              csi.closeExtension();
            }}
          >
            <TranslatedMessage
              id="launchV2Modal_launchV2"
              defaultMessage={'Launch the New AutoCut'}
            />
          </Button>
          <Button variant="tertiary" onClick={() => closeModal()}>
            <TranslatedMessage
              id="launchV2Modal_continueV1"
              defaultMessage={'Continue on the Old Version'}
            />
          </Button>
        </FlexContainer>
      }
      fullScreen
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
        style={{ height: '100%' }}
      >
        <Text variant="textSm.medium" color="white">
          <TranslatedMessage
            id="launchV2Modal_body"
            defaultMessage={
              'You’ve installed the new AutoCut but are currently running the old version, which is no longer maintained and may cause errors or interruptions.'
            }
          />
        </Text>
        <Text variant="textSm.medium" color="white">
          <TranslatedMessage
            id="launchV2Modal_features_title"
            defaultMessage={'Switch to the updated AutoCut now and enjoy:'}
          />
        </Text>
        <ul
          style={{
            color: 'white',
          }}
        >
          <li>
            <Text variant="textSm.medium" color="white">
              <TranslatedMessage
                id="launchV2Modal_features_transcription_history"
                defaultMessage={
                  'Transcription History – Quickly revisit past transcriptions in AutoCaptions'
                }
              />
            </Text>
          </li>
          <li>
            <Text variant="textSm.medium" color="white">
              <TranslatedMessage
                id="launchV2Modal_features_selection"
                defaultMessage={
                  'Enhanced Selection System – Speed up edits with intuitive selection'
                }
              />
            </Text>
          </li>
          <li>
            <Text variant="textSm.medium" color="white">
              <TranslatedMessage
                id="launchV2Modal_features_profanity"
                defaultMessage={
                  'Custom Profanity Filter – Easily choose your own beep sounds'
                }
              />
            </Text>
          </li>
          <li>
            <Text variant="textSm.medium" color="white">
              <TranslatedMessage
                id="launchV2Modal_features_podcast"
                defaultMessage={
                  'Optimized Podcast Algorithm – Get smoother transitions and cleaner audio'
                }
              />
            </Text>
          </li>
        </ul>
        <Text variant="textSm.medium" color="white">
          <TranslatedMessage
            id="launchV2Modal_features_footer"
            defaultMessage={
              'Upgrade to the new AutoCut for a faster and more stable editing experience.'
            }
          />
        </Text>

        <FlexContainer
          alignItems="center"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <img
            src={warningImage}
            alt="Warning icon"
            style={{
              width: 100,
            }}
          />
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
