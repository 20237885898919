import { InfoText } from '@autocut/components/atoms/InfoText';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './RestartPPro.module.scss';

export const RestartPPro = () => {
  return (
    <Modal
      closeOnClickOutside={false}
      withCloseButton={false}
      title={
        <div className={css.title}>
          <FormattedMessage
            id="restartPPro_title"
            defaultMessage={'Restart Premiere Pro'}
          />
        </div>
      }
      footer={
        <InfoText style="info" className={css.text}>
          <div>
            <FormattedMessage
              id="restartPPro_text2"
              defaultMessage={'Please  '}
            />
            <span className={css.bold}>
              <FormattedMessage
                id="restartPPro_text3"
                defaultMessage={'restart Premiere Pro'}
              />
            </span>
            <FormattedMessage
              id="restartPPro_text4"
              defaultMessage={' before using AutoCut.'}
            />
          </div>
        </InfoText>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s2}
        className={css.text}
        style={{ padding: '16px 0' }}
      >
        <div>
          <FormattedMessage
            id="restartPPro_text1"
            defaultMessage={
              'You have not restarted Premiere Pro since we have renamed your extensions.'
            }
          />
        </div>
        <div>
          <FormattedMessage
            id="restartPPro_text2"
            defaultMessage={'Please  '}
          />
          <span className={css.bold}>
            <FormattedMessage
              id="restartPPro_text3"
              defaultMessage={'restart Premiere Pro'}
            />
          </span>
          <FormattedMessage
            id="restartPPro_text4"
            defaultMessage={' before using AutoCut.'}
          />
        </div>
      </FlexContainer>
    </Modal>
  );
};
