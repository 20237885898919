import React, { useMemo } from 'react';

import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { getRandomAuthor } from '@autocut/components/atoms/Author/utils';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';
import { closeModal } from '@autocut/utils/modal/closeModal';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { Author } from '@autocut/components/atoms/Author/Author';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';

import { NewFeaturesList } from './NewFeature';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';

export const OnboardingMigrationModal = () => {
  const randomAuthor = useMemo(() => getRandomAuthor(), []);

  return (
    <Modal
      title={
        <TranslatedMessage
          id="onboarding_migration_title"
          defaultMessage="Autocut, remastered 🚀"
        />
      }
      withCloseButton={false}
      closeOnClickOutside={false}
      footer={
        <FlexContainer flexDirection="column" gap={8} style={{ marginTop: 8 }}>
          <Button
            color={colors.primary600}
            onClick={() => {
              openLinkInBrowser('https://www.autocut.com/en/download-beta/');
            }}
          >
            <TranslatedMessage
              id="onboarding_migration_upgrade"
              defaultMessage="Upgrade freely to the new AutoCut"
            />
          </Button>
          <Button onClick={closeModal} variant="tertiary">
            <TranslatedMessage
              id="onboarding_migration_continue"
              defaultMessage="Continue on old version"
            />
          </Button>
        </FlexContainer>
      }
    >
      <FlexContainer flexDirection="column" gap={16}>
        <FormSection title={<Author author={randomAuthor} />}>
          <PaddedSection>
            <Text color={colors.gray300}>
              <TranslatedMessage
                id="onboarding_migration_text"
                defaultMessage="You are currently running the old version of AutoCut. This version is not maintained anymore and we strongly advise you to download the new version of AutoCut.{br}This old version may cause errors or interruptions.{br}{br}Upgrade freely to the remastered version of AutoCut for a faster and more stable editing experience, and enjoy brand-new features such as:"
              />
            </Text>
          </PaddedSection>
        </FormSection>

        <NewFeaturesList />
      </FlexContainer>
    </Modal>
  );
};
